* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: black;
  font-family: "Nanum Gothic", sans-serif;
}

html,
body,
#root {
  margin: 0;
  padding: 0;
  min-height: 100vh !important;
}

li,
ul,
ol {
  list-style: none;
}

a {
  color: black;
  text-decoration: none;
}

img {
  width: 100%;
}

.txt-bold {
  font-weight: 700;
}

input:focus, textarea:focus {
  outline: none;
}/*# sourceMappingURL=core.css.map */