#root .login {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: space-around;
  background-color: #101820;
}
#root .login .wrapper {
  width: 350px;
}
#root .login .wrapper .logo {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 50px;
  font-weight: bolder;
  margin-top: 18px;
  color: white;
}
#root .login .wrapper .login-contents {
  margin-top: 30px;
}
#root .login .wrapper .login-contents .login-contents-title {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  color: #fcb355;
  font-weight: bolder;
}
#root .login .wrapper .login-contents .custom-inp {
  display: flex;
  align-items: center;
  margin-top: 10px;
}
#root .login .wrapper .login-contents .custom-inp .title {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  width: 100px;
  font-size: 17px;
  color: #fcb355;
}
#root .login .wrapper .login-contents .custom-inp .inp {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
#root .login .wrapper .login-contents .custom-inp .inp input {
  padding: 5px 10px;
  width: 250px;
  height: 38px;
  max-width: 310px;
  border-radius: 5px;
  border: 1px solid gray;
}
#root .login .wrapper .login-contents .custom-inp .inp input::-moz-placeholder {
  font-size: 12px;
  font-weight: bolder;
}
#root .login .wrapper .login-contents .custom-inp .inp input::placeholder {
  font-size: 12px;
  font-weight: bolder;
}
#root .login .wrapper .login-contents .id-find {
  color: #fcb355;
  margin-top: 10px;
  margin-right: 10px;
  font-size: 13px;
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}
#root .login .wrapper .login-contents .login-btn {
  width: 100%;
  height: 38px;
  margin-top: 20px;
  border: 1px solid gray;
  border-radius: 5px;
  font-size: 15px;
  cursor: pointer;
}
#root .login .wrapper .go-join {
  width: 100%;
  height: 38px;
  margin-top: 20px;
  border: 1px solid gray;
  border-radius: 5px;
  font-size: 15px;
  cursor: pointer;
}
#root .login .wrapper .guest-login {
  width: 100%;
  height: 38px;
  margin-top: 20px;
  border: 1px solid gray;
  border-radius: 5px;
  font-size: 15px;
  cursor: pointer;
}
#root .login .wrapper .guest-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1000;
}
#root .login .wrapper .guest-background .guest-window {
  position: relative;
  width: 100%;
  height: 100%;
}
#root .login .wrapper .guest-background .guest-window .guest-popup {
  position: fixed;
  overflow: hidden;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  box-shadow: 0 2px 7px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  width: 300px;
  height: 270px;
}
#root .login .wrapper .guest-background .guest-window .guest-popup .guest-exit {
  margin-top: 10px;
  padding-right: 15px;
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}
#root .login .wrapper .guest-background .guest-window .guest-popup .guest-title {
  margin-top: 25px;
  padding: 0px 50px;
  font-weight: bolder;
}
#root .login .wrapper .guest-background .guest-window .guest-popup .guest-content {
  padding: 0px 50px;
  padding-bottom: 30px;
  margin-top: 25px;
  font-weight: bolder;
}
#root .login .wrapper .guest-background .guest-window .guest-popup .guest-content .gender-inp {
  display: flex;
  align-items: center;
}
#root .login .wrapper .guest-background .guest-window .guest-popup .guest-content .gender-inp .title {
  color: black;
}
#root .login .wrapper .guest-background .guest-window .guest-popup .guest-content .gender-inp .custom-checkbox {
  display: flex;
  align-items: center;
  margin-left: 20px;
}
#root .login .wrapper .guest-background .guest-window .guest-popup .guest-content .gender-inp .custom-checkbox input {
  margin-right: 8px;
}
#root .login .wrapper .guest-background .guest-window .guest-popup .guest-content .birthday-inp {
  margin-top: 20px;
}
#root .login .wrapper .guest-background .guest-window .guest-popup .guest-content .birthday-inp .title {
  color: black;
}
#root .login .wrapper .guest-background .guest-window .guest-popup .guest-content .birthday-inp .inp {
  margin-top: 10px;
}
#root .login .wrapper .guest-background .guest-window .guest-popup .guest-popup-buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  font-weight: bolder;
}
#root .login .wrapper .guest-background .guest-window .guest-popup .guest-popup-buttons .guest-check,
#root .login .wrapper .guest-background .guest-window .guest-popup .guest-popup-buttons .guest-skip {
  cursor: pointer;
  padding: 3px 5px;
  border: 1px solid black;
  border-radius: 5px;
}
#root .login .wrapper .guest-background .guest-window .guest-popup .guest-popup-buttons .guest-check {
  margin-right: 30px;
  margin-left: 10px;
}/*# sourceMappingURL=login.css.map */