$class-101-gray: #e8e8e8;
$class-101-gray100: #9D9D9F;
$class-101-gray200: #454545;
$class-101-main: #373f50;
$class-101-blue: #5085e8;
$class-101-black: #262626;
$class-101-white-200 : #fafafa;

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: black;
  font-family: 'Nanum Gothic',
    sans-serif;
}

html,
body,
#root {
  margin: 0;
  padding: 0;
  min-height: 100vh !important;
}

li,
ul,
ol {
  list-style: none;
}

a {
  color: black;
  text-decoration: none;
}

img {
  width: 100%;
}

.txt-bold {
  font-weight: 700;
}

input, textarea {
  &:focus {
    outline: none;
  }
}