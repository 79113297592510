$content-color: #fcb355;

#root {
  .login {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: space-around;
    background-color: #101820;

    .wrapper {
      width: 350px;

      .logo {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 50px;
        font-weight: bolder;
        margin-top: 18px;
        color: white;
      }

      .login-contents {
        margin-top: 30px;

        .login-contents-title {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 25px;
          color: $content-color;
          font-weight: bolder;
        }

        .custom-inp {
          display: flex;
          align-items: center;
          margin-top: 10px;

          .title {
            display: block;
            margin-block-start: 1em;
            margin-block-end: 1em;
            margin-inline-start: 0px;
            margin-inline-end: 0px;
            width: 100px;
            font-size: 17px;
            color: $content-color
          }

          .inp {
            display: flex;
            flex-direction: column;
            gap: 10px;

            input {
              padding: 5px 10px;
              width: 250px;
              height: 38px;
              max-width: 310px;
              border-radius: 5px;
              border: 1px solid gray;
            }

            input::placeholder {
              font-size: 12px;
              font-weight: bolder;
            }
          }
        }

        .id-find {
          color: $content-color;
          margin-top: 10px;
          margin-right: 10px;
          font-size: 13px;
          display: flex;
          justify-content: flex-end;
          cursor: pointer;
        }

        .login-btn {
          width: 100%;
          height: 38px;
          margin-top: 20px;
          border: 1px solid gray;
          border-radius: 5px;
          font-size: 15px;
          cursor: pointer;
        }
      }

      .go-join {
        width: 100%;
        height: 38px;
        margin-top: 20px;
        border: 1px solid gray;
        border-radius: 5px;
        font-size: 15px;
        cursor: pointer;
      }

      .guest-login {
        width: 100%;
        height: 38px;
        margin-top: 20px;
        border: 1px solid gray;
        border-radius: 5px;
        font-size: 15px;
        cursor: pointer;
      }

      .guest-background {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.3);
        z-index: 1000;

        .guest-window {
          position: relative;
          width: 100%;
          height: 100%;

          .guest-popup {
            position: fixed;
            overflow: hidden;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background-color: #ffffff;
            box-shadow: 0 2px 7px rgba(0, 0, 0, 0.3);
            border-radius: 5px;

            width: 300px;
            height: 270px;

            .guest-exit {
              margin-top: 10px;
              padding-right: 15px;
              display: flex;
              justify-content: flex-end;
              cursor: pointer;
            }

            .guest-title {
              margin-top: 25px;
              padding: 0px 50px;
              font-weight: bolder;
            }

            .guest-content {
              // height: 65%;
              padding: 0px 50px;
              padding-bottom: 30px;
              margin-top: 25px;
              // margin-bottom: 30px;
              font-weight: bolder;

              // 체크박스, 생년월일 꾸미기 박스배치는 회원가입 부분 참조
              .gender-inp {
                display: flex;
                align-items: center;

                .title {
                  color: black;
                }

                .custom-checkbox {
                  display: flex;
                  align-items: center;
                  margin-left: 20px;

                  input {
                    margin-right: 8px;
                  }
                }
              }

              .birthday-inp {
                margin-top: 20px;

                .title {
                  color: black;
                }

                .inp {
                  margin-top: 10px;
                }
              }
            }

            .guest-popup-buttons {
              display: flex;
              flex-direction: row;
              justify-content: flex-end;
              align-items: flex-end;
              font-weight: bolder;

              .guest-check,
              .guest-skip {
                // display: flex;
                // justify-content: flex-end;
                // align-items: flex-end;
                // font-weight: bolder;
                cursor: pointer;
                // // margin-left: auto;
                padding: 3px 5px;
                border: 1px solid black;
                border-radius: 5px;
                // margin-right: 50px;
                // margin-bottom: 30px;
                // padding-right: 7px;
                // padding-top: 3px;
                // padding-bottom: 3px;
              }

              .guest-check {
                margin-right: 30px;
                margin-left: 10px;
              }
            }
          }
        }
      }
    }
  }
}