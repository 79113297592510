$content-color: #fcb355;

#root {
  .join {
    min-height: 100vh;
    display: flex;
    // align-items: center;
    justify-content: center;
    align-content: space-around;
    background-color: #101820;

    .wrapper {
      width: 430px;

      .logo {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 50px;
        font-weight: bolder;
        margin-top: 18px;
        color: white;
      }

      .join-contents {
        margin-top: 28px;

        .join-contents-title {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 30px;
          color: $content-color;
          font-weight: bolder;
        }

        .custom-inp {
          display: flex;
          // justify-content: flex-start;
          align-items: center;
          margin-top: 25px;

          .title {
            display: block;
            margin-block-start: 1em;
            margin-block-end: 1em;
            margin-inline-start: 0px;
            margin-inline-end: 0px;
            width: 120px;
            font-size: 17px;
            color: $content-color
          }

          .inp {
            display: flex;
            flex-direction: column;
            gap: 10px;

            input {
              padding: 5px 10px;
              width: 310px;
              height: 38px;
              max-width: 310px;
              border-radius: 5px;
              border: 1px solid gray;
            }

            input::placeholder {
              font-size: 10px;
              font-weight: bolder;
            }
          }
        }

        .gender-inp {
          display: flex;
          align-items: center;
          margin-top: 25px;

          .title {
            display: block;
            margin-block-start: 1em;
            margin-block-end: 1em;
            margin-inline-start: 0px;
            margin-inline-end: 0px;
            width: 120px;
            font-size: 17px;
            color: $content-color;
          }

          .custom-checkbox {
            display: flex;
            align-items: center;
            margin-right: 80px;
            /* 라벨과 체크박스 사이 간격 설정 */

            input[type="radio"] {
              // appearance: none;
              width: 20px;
              /* 라디오 버튼의 너비 조절 */
              height: 20px;
              /* 라디오 버튼의 높이 조절 */
              border: 2px solid #fcb355;
              /* 라디오 버튼 외곽선 스타일링 */
              border-radius: 50%;
              /* 라디오 버튼을 둥글게 만듭니다. */
              margin-right: 5px;
              /* 라디오 버튼과 텍스트 사이 간격 설정 */
            }

            label {
              color: #ffffff;
              font-size: 17px;
            }
          }
        }

        // .gender-inp {
        //   // color: #fcb355;
        //   display: flex;
        //   // justify-content: flex-start;
        //   align-items: center;
        //   margin-top: 25px;

        //   .title {
        //     display: block;
        //     margin-block-start: 1em;
        //     margin-block-end: 1em;
        //     margin-inline-start: 0px;
        //     margin-inline-end: 0px;
        //     width: 120px;
        //     font-size: 17px;
        //     color: $content-color
        //   }

        //   .inp {
        //     display: flex;
        //     // flex-direction: column;
        //     align-items: center;
        //     gap: 10px;

        //     input {
        //       // padding: 5px 10px;
        //       width: 70px;
        //       height: 20px;
        //       max-width: 310px;
        //       border-radius: 5px;
        //       border: 1px solid gray;

        //     }

        //     label {
        //       color: #ffffff;
        //       // top: 5px;

        //     }
        //   }


      }

      .checkbox-agree-inp {
        width: 100%;
        margin-top: 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        label {
          color: $content-color;
          margin-left: 12px;
          cursor: pointer;
        }
      }

      .join-btn {
        width: 100%;
        height: 38px;
        margin-top: 30px;
        margin-bottom: 50px;
        border: 1px solid gray;
        border-radius: 5px;
        font-size: 15px;
        cursor: pointer;
      }
    }

    .terms-background {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      background-color: rgba(0, 0, 0, 0.3);
      z-index: 1000;
      // display: none;

      .terms-window {
        position: relative;
        width: 100%;
        height: 100%;

        .terms-popup {
          position: fixed;
          overflow: hidden;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          background-color: #ffffff;
          box-shadow: 0 2px 7px rgba(0, 0, 0, 0.3);
          border-radius: 5px;

          width: 300px;
          height: 500px;

          .terms-exit {
            margin-top: 10px;
            padding-right: 15px;
            display: flex;
            justify-content: flex-end;
          }

          .terms-title {
            margin-top: 25px;
            padding: 0px 50px;
            font-weight: bolder;
          }

          .terms-content {
            height: 65%;
            padding: 0px 50px;
            padding-bottom: 30px;
            margin-top: 25px;
            margin-bottom: 30px;
            overflow-y: auto;
            font-weight: bolder;
          }

          .terms-content::-webkit-scrollbar {
            display: none;
          }

          .terms-check {
            display: flex;
            justify-content: flex-end;
            margin-left: 200px;
            margin-right: 50px;
            margin-bottom: 30px;
            padding-right: 7px;
            padding-top: 3px;
            padding-bottom: 3px;
            // border: 2px solid black;
            // border-radius: 5px;
            font-weight: bolder;
            cursor: pointer;
            transition: all 0.35s;

          }

          // .terms-check:hover {
          //     display: flex;
          //     justify-content: flex-end;
          //     margin-left: 200px;
          //     margin-right: 50px;
          //     margin-bottom: 30px;
          //     padding-right: 7px;
          //     padding-top: 3px;
          //     padding-bottom: 3px;
          //     // border: 2px solid black;
          //     // border-radius: 5px;
          //     font-weight: bolder;
          //     cursor: pointer;
          //     background-color: black;
          //     color: white;
          // }
        }
      }
    }

    .privacy-background {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      background-color: rgba(0, 0, 0, 0.3);
      z-index: 1000;
      // display: none;

      .privacy-window {
        position: relative;
        width: 100%;
        height: 100%;

        .privacy-popup {
          position: fixed;
          overflow: hidden;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          background-color: #ffffff;
          box-shadow: 0 2px 7px rgba(0, 0, 0, 0.3);
          border-radius: 5px;

          width: 300px;
          height: 500px;

          .privacy-exit {
            margin-top: 10px;
            padding-right: 15px;
            display: flex;
            justify-content: flex-end;
          }

          .privacy-title {
            margin-top: 25px;
            padding: 0px 50px;
            font-weight: bolder;
          }

          .privacy-content {
            height: 65%;
            padding: 0px 50px;
            padding-bottom: 30px;
            margin-top: 25px;
            margin-bottom: 30px;
            overflow-y: auto;
            font-weight: bolder;
          }

          .privacy-content::-webkit-scrollbar {
            display: none;
          }

          .privacy-check {
            display: flex;
            justify-content: flex-end;
            margin-left: 200px;
            margin-right: 50px;
            margin-bottom: 30px;
            padding-right: 7px;
            padding-top: 3px;
            padding-bottom: 3px;
            // border: 2px solid black;
            // border-radius: 5px;
            font-weight: bolder;
            cursor: pointer;
            transition: all 0.35s;

          }
        }
      }
    }

  }
}