$content-color: #fcb355;

#root {
  margin: 0;

  // .welcome {
  //   height: 100vh;
  //   display: flex;
  //   // align-items: center;
  //   // justify-content: center;
  //   background-color: #101820;
  //   margin: 0;

    // .wrapper {
    //   // width: 350px;
    //   margin: 0;

    //   .welcome-contents {
    //     margin: 0;

        // .go-login {
        //   width: 100%;
        //   height: 38px;
        //   margin-top: 20px;
        //   border: 1px solid gray;
        //   border-radius: 5px;
        //   font-size: 15px;
        //   cursor: pointer;
        // }

        // .go-content {
        //   width: 100%;
        //   height: 38px;
        //   margin-top: 20px;
        //   border: 1px solid gray;
        //   border-radius: 5px;
        //   font-size: 15px;
        //   cursor: pointer;

        // }

        .unity-view {
          margin: 0;
          width: 100vw;
          height: 100vh;
          overflow: hidden;

          .unity-app {
            width: 100%;
            height: 100%;
            overflow: hidden;
          }
        }

      // }
    // }
//   }
}