@charset "UTF-8";
#root .join {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-content: space-around;
  background-color: #101820;
}
#root .join .wrapper {
  width: 430px;
}
#root .join .wrapper .logo {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 50px;
  font-weight: bolder;
  margin-top: 18px;
  color: white;
}
#root .join .wrapper .join-contents {
  margin-top: 28px;
}
#root .join .wrapper .join-contents .join-contents-title {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  color: #fcb355;
  font-weight: bolder;
}
#root .join .wrapper .join-contents .custom-inp {
  display: flex;
  align-items: center;
  margin-top: 25px;
}
#root .join .wrapper .join-contents .custom-inp .title {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  width: 120px;
  font-size: 17px;
  color: #fcb355;
}
#root .join .wrapper .join-contents .custom-inp .inp {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
#root .join .wrapper .join-contents .custom-inp .inp input {
  padding: 5px 10px;
  width: 310px;
  height: 38px;
  max-width: 310px;
  border-radius: 5px;
  border: 1px solid gray;
}
#root .join .wrapper .join-contents .custom-inp .inp input::-moz-placeholder {
  font-size: 10px;
  font-weight: bolder;
}
#root .join .wrapper .join-contents .custom-inp .inp input::placeholder {
  font-size: 10px;
  font-weight: bolder;
}
#root .join .wrapper .join-contents .gender-inp {
  display: flex;
  align-items: center;
  margin-top: 25px;
}
#root .join .wrapper .join-contents .gender-inp .title {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  width: 120px;
  font-size: 17px;
  color: #fcb355;
}
#root .join .wrapper .join-contents .gender-inp .custom-checkbox {
  display: flex;
  align-items: center;
  margin-right: 80px;
  /* 라벨과 체크박스 사이 간격 설정 */
}
#root .join .wrapper .join-contents .gender-inp .custom-checkbox input[type=radio] {
  width: 20px;
  /* 라디오 버튼의 너비 조절 */
  height: 20px;
  /* 라디오 버튼의 높이 조절 */
  border: 2px solid #fcb355;
  /* 라디오 버튼 외곽선 스타일링 */
  border-radius: 50%;
  /* 라디오 버튼을 둥글게 만듭니다. */
  margin-right: 5px;
  /* 라디오 버튼과 텍스트 사이 간격 설정 */
}
#root .join .wrapper .join-contents .gender-inp .custom-checkbox label {
  color: #ffffff;
  font-size: 17px;
}
#root .join .wrapper .checkbox-agree-inp {
  width: 100%;
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
#root .join .wrapper .checkbox-agree-inp label {
  color: #fcb355;
  margin-left: 12px;
  cursor: pointer;
}
#root .join .wrapper .join-btn {
  width: 100%;
  height: 38px;
  margin-top: 30px;
  margin-bottom: 50px;
  border: 1px solid gray;
  border-radius: 5px;
  font-size: 15px;
  cursor: pointer;
}
#root .join .terms-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1000;
}
#root .join .terms-background .terms-window {
  position: relative;
  width: 100%;
  height: 100%;
}
#root .join .terms-background .terms-window .terms-popup {
  position: fixed;
  overflow: hidden;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  box-shadow: 0 2px 7px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  width: 300px;
  height: 500px;
}
#root .join .terms-background .terms-window .terms-popup .terms-exit {
  margin-top: 10px;
  padding-right: 15px;
  display: flex;
  justify-content: flex-end;
}
#root .join .terms-background .terms-window .terms-popup .terms-title {
  margin-top: 25px;
  padding: 0px 50px;
  font-weight: bolder;
}
#root .join .terms-background .terms-window .terms-popup .terms-content {
  height: 65%;
  padding: 0px 50px;
  padding-bottom: 30px;
  margin-top: 25px;
  margin-bottom: 30px;
  overflow-y: auto;
  font-weight: bolder;
}
#root .join .terms-background .terms-window .terms-popup .terms-content::-webkit-scrollbar {
  display: none;
}
#root .join .terms-background .terms-window .terms-popup .terms-check {
  display: flex;
  justify-content: flex-end;
  margin-left: 200px;
  margin-right: 50px;
  margin-bottom: 30px;
  padding-right: 7px;
  padding-top: 3px;
  padding-bottom: 3px;
  font-weight: bolder;
  cursor: pointer;
  transition: all 0.35s;
}
#root .join .privacy-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1000;
}
#root .join .privacy-background .privacy-window {
  position: relative;
  width: 100%;
  height: 100%;
}
#root .join .privacy-background .privacy-window .privacy-popup {
  position: fixed;
  overflow: hidden;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  box-shadow: 0 2px 7px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  width: 300px;
  height: 500px;
}
#root .join .privacy-background .privacy-window .privacy-popup .privacy-exit {
  margin-top: 10px;
  padding-right: 15px;
  display: flex;
  justify-content: flex-end;
}
#root .join .privacy-background .privacy-window .privacy-popup .privacy-title {
  margin-top: 25px;
  padding: 0px 50px;
  font-weight: bolder;
}
#root .join .privacy-background .privacy-window .privacy-popup .privacy-content {
  height: 65%;
  padding: 0px 50px;
  padding-bottom: 30px;
  margin-top: 25px;
  margin-bottom: 30px;
  overflow-y: auto;
  font-weight: bolder;
}
#root .join .privacy-background .privacy-window .privacy-popup .privacy-content::-webkit-scrollbar {
  display: none;
}
#root .join .privacy-background .privacy-window .privacy-popup .privacy-check {
  display: flex;
  justify-content: flex-end;
  margin-left: 200px;
  margin-right: 50px;
  margin-bottom: 30px;
  padding-right: 7px;
  padding-top: 3px;
  padding-bottom: 3px;
  font-weight: bolder;
  cursor: pointer;
  transition: all 0.35s;
}/*# sourceMappingURL=join.css.map */