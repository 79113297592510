#root {
  margin: 0;
}
#root .unity-view {
  margin: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}
#root .unity-view .unity-app {
  width: 100%;
  height: 100%;
  overflow: hidden;
}/*# sourceMappingURL=welcome.css.map */